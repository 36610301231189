<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-people"/>
          Einen neuen Mitarbeiter anlegen
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="newUser()">

              <MitarbeiterText v-model="name" formLabel="Name"/>
              <MitarbeiterEmail v-model="email"/>
              <CSelect
                label="Titel"
                :value.sync="titel"
                :isValid="true"
                :options="statusTitel"
                class="mt-2"
              />
              <MitarbeiterNumber v-model="sollstunden" formLabel="Soll-Stunden Arbeitszeit (pro Woche)"/>
              <MitarbeiterNumber v-model="tageskalender" formLabel="Anzahl Wochen, die rückwirkend der Tageskalender eingesehen werden kann"/>
              <CSelect
                label="Zugriffsrechte"
                :value.sync="roles"
                :isValid="true"
                :options="statusRoles"
                class="mt-2"
              />
              <div class="checkboxen mt-4">
                <div class="form-group">
                  <CSwitch :disabled="user.roles.includes('ROLE_SUPER_ADMIN')?false:true" class="py-2" color="success" :checked.sync="tageskalenderAktiv"/>
                  <span class="label px-3">Tageskalender Aktiv</span>
                </div>
              </div>
              <MitarbeiterPassword v-model="plainPassword" formLabel="Passwort" class="mt-3" />

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Mitarbeiter anlegen</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import MitarbeiterEmail from '@/components/FormElements/Email'
import MitarbeiterText from '@/components/FormElements/Text'
import MitarbeiterNumber from '@/components/FormElements/Number'
import MitarbeiterPassword from '@/components/FormElements/Password'

export default {
  name: 'MitarbeiterNew',
  components: {
    FormError,
    MitarbeiterEmail,
    MitarbeiterText,
    MitarbeiterNumber,
    MitarbeiterPassword
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  data () {
    return {
      email: '',
      name: '',
      titel: '',
      sollstunden: 40,
      tageskalenderAktiv: false,
      tageskalender: 4,
      roles: '',
      plainPassword: '',
      formError: null,
      statusTitel: [
        { value: '10', label: 'Helfer' },
        { value: '20', label: 'Programmierer' },
        { value: '30', label: 'Monteur' },
        { value: '40', label: 'Obermonteur' },
        { value: '50', label: 'Kundendiensttechniker' },
        { value: '60', label: 'Meister' },
        { value: '70', label: 'Systemtechniker' }
      ],
      statusRoles: [
        { value: 'ROLE_KALENDER', label: 'Kalender' },
        { value: 'ROLE_TEAM', label: 'Monteure/Mitarbeiter' },
        { value: 'ROLE_REPORT', label: 'Report/Verwaltung' },
        { value: 'ROLE_ADMIN', label: 'Admininstrator' }
      ]
    }
  },
  mounted () {
    if (this.user.roles.includes('ROLE_SUPER_ADMIN')) {
      const statusRolesPlus = this.statusRoles
      statusRolesPlus.push({ value: 'ROLE_SUPER_ADMIN', label: 'Admininstrator*' })
      this.statusRoles = statusRolesPlus
    }
    this.setDefaults()
  },
  methods: {
    setDefaults () {
      this.email = ''
      this.name = ''
      this.titel = '30'
      this.sollstunden = 40
      this.tageskalender = 4
      this.roles = 'ROLE_TEAM'
      this.plainPassword = ''
      this.formError = null
    },
    newUser () {
      Vue.axios.post('/mitarbeiter/post', {
        email: this.email,
        name: this.name,
        titel: this.titel,
        sollstunden: this.sollstunden,
        tageskalenderAktiv: this.tageskalenderAktiv,
        tageskalender: this.tageskalender,
        roles: this.roles,
        plainPassword: this.plainPassword
      }).then((response) => {
        this.$router.push({ path: '/mitarbeiter/uebersicht' })
        this.$snotify.success('Der neue Mitarbeiter wurde angelegt.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.setDefaults()
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
